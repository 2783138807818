/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-01-11 11:57:10
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-09-20 16:27:07
 * @FilePath: /mediatom-web/src/views/media/adMain/options.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { numFormat } from '@/utils/dealNumber'
import { defaultStyleVar } from '@/config/settingStyle'
export default {
  grid: {
    left: '5',
    top: '5',
    right: '5',
    bottom: '5'
  },
  tooltip: {
    appendToBody: true,
    trigger: 'axis',
    axisPointer: {
      type: 'none'
    },
    formatter: (params) => {
      let str = ''
      params.forEach((item, index) => {
        str += item.marker + '预估收益' + ': ' + numFormat(params[0].data, 3, '') + '<br>'
      })
      return params[0].axisValue + '<br>' + str
    }
  },
  xAxis: {
    show: false,
    type: 'category',
    boundaryGap: false,
    data: []
  },
  yAxis: {
    type: 'value',
    show: false
  },
  series: [
    {
      data: [],
      type: 'line',
      showSymbol: false,
      areaStyle: {
        color: defaultStyleVar['--assisColor'],
        opacity: 0.1
      },
      itemStyle: {
        normal: {
          color: defaultStyleVar['--assisColor'], // 改变折线点的颜色
          lineStyle: {
            color: defaultStyleVar['--assisColor'] // 改变折线颜色
          }
        }
      }
    }
  ]
}
